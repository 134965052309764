/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;
@import "../node_modules/flag-icons/css/flag-icons.min.css";
@import "./app/betabit-dark-theme.scss";
@import "./app/betabit-light-theme.scss";

@include mat.core();

html,
body {
  height: 100%;
}
body {
  margin: 0;
}

.mat-mdc-header-row {
  background-color: #b70d31 !important;
  color: #fff !important;
  border-radius: 5px;
  text-align: center;
  padding: 10px;
  border-bottom: 2px solid #ccc;
}

.mat-card-header {
  padding: 10px;
  border-radius: 10px;
  background-color: #b70d31;
  width: 400px;
  margin-bottom: -60px;
  z-index: 4;
  justify-content: center;
  position: absolute;
  margin-top: -35px;
  align-self: center;
  width: 95%;
  color: #fff;
}

.mdc-snackbar__surface {
  min-width: 150px;
  min-height: 75px;
  max-width: 150px;
  color: #fff;
  font-weight: 500;
}

.table-container {
  overflow-x: auto;
}

.icon-check {
  color: #9dc384;
}

.icon-cancel {
  color: #e74c3c;
}

.icon-pending {
  color: #f1c40f;
}

.icon-check:disabled {
  color: gray;
}

.mat-drawer-inner-container::-webkit-scrollbar {
  background-color: white;
  width: 4px;
  border-radius: 4px;
}

.mat-drawer-inner-container::-webkit-scrollbar-track {
  border-radius: 4px;
}

.mat-drawer-inner-container::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
  border: 2px solid #b70d31;
}

.mat-drawer-content::-webkit-scrollbar {
  background-color: white;
  width: 4px;
  border-radius: 4px;
}

.mat-drawer-content::-webkit-scrollbar-track {
  border-radius: 4px;
}

.mat-drawer-content::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
  border: 2px solid #b70d31;
}

.snackbar-success {
  --mdc-snackbar-container-color: #9dc384;
  color: #fff;
}

.snackbar-error {
  --mdc-snackbar-container-color: #e74c3c;
  color: #fff;
}
