@use "@angular/material" as mat;

$betabit-primary: mat.m2-define-palette(mat.$m2-red-palette, 900);
$betabit-accent: mat.m2-define-palette(mat.$m2-gray-palette, 100);
$dark-theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $betabit-primary,
      accent: $betabit-accent,
    ),
    typography:
      mat.m2-define-typography-config(
        $font-family: "Montserrat, sans-serif",
      ),
  )
);

.dark-theme {
  @include mat.all-component-themes($dark-theme);
}
